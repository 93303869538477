<template>
	<!-- <div class="wrap"> -->
		<div id="app">
			<div id="demo"></div>
			<van-col v-show="show" span="20" offset="2" style="position:fixed;bottom:10px;">
				<van-button round block type="danger" :disabled="disable" @click="handdleSubmit">
					签名
				</van-button>
			</van-col>
		</div>
	<!-- </div>   -->
</template>
 
<script>
import Pdfh5 from 'pdfh5';
import axios from 'axios';
import {generateContract,getPdfsUrl,getImgToken } from '@/server';
import $ from 'jquery';
import {setCookie,getCookie} from '@/lib/utils';
import { Toast } from 'vant';
  export default {
    data () {
      return {
				pdfh5: null,
        openId:getCookie('openId'),
				// agreementUrl:this.$route.query.url,
        agreementUrl:'',
				disable:true,
        show:true,
        token:'',
        authorDataList: this.$store.state.authorData,
        addressList:[]
      }
    },
    mounted(){
			sessionStorage.setItem('key', 'card4');
      let that = this;
      window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
      if(sessionStorage.getItem('key') == 'card4') {
        that.$router.push({ path: '/modifyDebitCard' });
        // window.history.pushState('forward', null, '/modifyDebitCard');
      }
      that.$store.commit('backFlagNum',that.authorDataList.contract_number);
      let isReadonly = that.authorDataList.isReadonly;
       if(isReadonly==false){
         that.$store.commit('backFlag','1');
          if(that.authorDataList.sign_status == '已签约'){
            // that.$store.commit('writeData', that.authorDataList);
            that.$store.commit('backFlagNum', that.authorDataList.contract_number); 
          }
        }else{
          that.$store.commit('backFlag','0');
          // that.$store.commit('writeData', that.authorDataList);
          that.$store.commit('backFlagNum', that.authorDataList.contract_number);
        }
      })
　　},
  created(){
    const data = this.authorDataList;
    //获取token
    getImgToken({openid:this.openId}).then(res=>{
      if(res.wx_code=='0'){
        localStorage.setItem("token", res.img_token); 
        this.token = res.img_token;
        if(data.sign_status == '已签约'){
          this.show = false;
          this.agreementUrl = data.signimg_url+'?Authorization='+res.img_token;
          this.getPdfUrl();
        }else{
          this.show = true;
          this.getDetail()
        }
      }
    })
  },
    methods: {
      getDetail(){
        const dataList= this.authorDataList;
        let templeGroup='';
        let aName,aCard,aBank
        const {
          enddate,
          applydate,
          bankValue
        } = dataList;
        if(bankValue == '建设银行'){
          templeGroup="OLRZZLCON_XY_JH";
        }else if(bankValue == '中国银行'){
          templeGroup="OLRZZLCON_XY_ZH";
          aName = dataList.boc_name;
          aCard = dataList.boc_number;
          aBank = dataList.boc_bank;
        }else if(bankValue == '邮储银行'){
          templeGroup="OLRZZLCON_XY_YZ";
          aName = dataList.psbc_name;
          aCard = dataList.psbc_number;
          aBank = dataList.psbc_bank;
        }
        const startYear = applydate.substr(0,4);
        const startMonth = applydate.substr(5,2);
        const startDay = applydate.substr(8,2);
        const endYear = enddate.substr(0,4);
        const endMonth = enddate.substr(5,2);
        const endDay = enddate.substr(8,2);
        const params = {
          templeGroup:templeGroup,   //模板组 中行 OLRZZLCON_XY_YZ  中国邮政划款授权书 OLRZZLCON_XY_JH 建行划款授权书
          leaseType:0,  // 租赁 0 直租 1回租 3直租续租
          contractMap:{
            userName:dataList.customername,
            cardId:dataList.card_id,
            phoneNo:dataList.mobile,
            refundCard:dataList.cardNum,
            refundBank:dataList.customername,
            startYear:startYear,
            startMonth:startMonth,
            startDay:startDay,
            endYear:endYear,
            endMonth:endMonth,
            endDay:endDay,
            aName:aName,
            aCard:aCard,
            aBank:aBank,
            cardIdType:dataList.certtype,
            signDate:dataList.enddate
          }
        }
        generateContract(params).then(res=>{
          const{status,data} = res
          if(status==200){
            this.addressList = data.list;
            this.agreementUrl = data.list[0].address+'?Authorization='+this.token;
            // this.agreementUrl = data.list[0].address
            // console.log(this.agreementUrl)
            this.getPdfUrl();
          }else{
            Toast.fail({
              message: res.subMsg,
              duration:'3000'
            });
          }
          
        })
      },
      getPdfUrl(){
        // axios.defaults.headers['authorization'] = `Bearer ${token}`;
        var url = this.agreementUrl;
        // 	$.ajax({
        //     headers: {
        //       "Authorization": "Bearer "+this.token
        //     },
        //     url: 'http://10.6.132.166:8263/oraflfile/content/group2/M00/00/47/CgaEpmAwcBqADyPrAAWDaKEy9A0115.pdf',
        //     // data:{"group":"1","name":"12.png","content":that.dataBase},
        //     type: "get",
        //     success: function (res) {}
        // })
        axios.get(url, {
          responseType: 'arraybuffer',
        })
        .then(res => {
          this.pdfh5 = new Pdfh5('#demo', {
            data: res.data
          });
          this.disable = false;
        });
      },
			handdleSubmit(){
        const paramsData = this.authorDataList;
        paramsData.addressList = this.addressList;
        this.$store.commit('authorData', paramsData);
				this.$router.push({path: '/transferSign'})
			}
    }
  }
</script>

<style>
/* @import '@/lib/pdfh5.css'; */
* {
  padding: 0;
  margin: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
